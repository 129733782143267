import { Routes } from '@3as-affiliates/partners/web/types-configs';
import {
  AffiliationType,
  AllPartners,
  OrganizationInvertedKeys,
} from '@3as-affiliates/shared/types-configs';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useClickAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  padding: 0 1rem;
`;

const StyledFooter = styled(Footer)`
  margin-top: 1.5rem;
  font-size: 1rem;
`;

const StyledHeader = styled(Header)`
  padding-bottom: 0.5rem;
`;

interface Props {
  children: ReactNode;
  pageName: string;
  showUserAvatar?: boolean;
}

export const PageContainerWithViewAnalyticsHeaderFooter = ({
  children,
  pageName,
  showUserAvatar = true,
}: Props) => {
  const { affiliateInfo } = useAppData();
  const affiliations = affiliateInfo?.affiliations as
    | AffiliationType[]
    | undefined;
  const affiliation = affiliations?.[0];
  const orgId = affiliation?.organizationId || '';
  const partner = OrganizationInvertedKeys[orgId] || '';

  // Footer links based on partner type
  const termsOfUseUrl =
    partner === AllPartners.VIU
      ? `https://www.asurion.com/legal/viu`
      : '/terms-conditions';
  const privacyPolicyUrl = '/privacy-notice';

  const navigate = useNavigate();
  const { dispatchClickAnalytics } = useClickAnalytics();

  // Handlers
  const handleProfileClick = () => {
    dispatchClickAnalytics({
      Cta: 'Header Profile Menu Item Click button',
      ActionId: 'ProfileMenuItem',
    });
  };

  const handleProvideFeedbackClick = () => {
    dispatchClickAnalytics({
      Cta: 'Header Report Bug Item Click button',
      ActionId: 'ProvideFeedbackMenuItem',
    });
    navigate(Routes.LoggedInProvideFeedback);
  };

  return (
    <PageContainerWithViewAnalytics pageName={pageName}>
      <StyledHeader
        pageName={pageName}
        showUserAvatar={showUserAvatar}
        onProfileClick={handleProfileClick}
        onProvideFeedbackClick={handleProvideFeedbackClick}
      />
      <StyledContainer>{children}</StyledContainer>
      <StyledFooter
        termsOfUseUrl={termsOfUseUrl}
        privacyPolicyUrl={privacyPolicyUrl}
      />
    </PageContainerWithViewAnalytics>
  );
};
