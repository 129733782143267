import {
  PageContainerWithViewAnalyticsHeaderFooter,
  TypeProductContentConfig,
} from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import {
  AllPartners,
  AllProducts,
  Partner,
  ProductSlugs,
  ProductSlugsInvertedKeys,
} from '@3as-affiliates/shared/types-configs';
import { Text } from '@soluto-private/mx-asurion-ui-react-v3';
import { useLocation, useParams } from 'react-router-dom';
import { acrisureAppliancePlusProductContent } from '../../partner-data/content/acrisure/appliance-plus';
import { acrisureHomePlusProductContent } from '../../partner-data/content/comparion/home-plus';
import { NotFound } from '../NotFound';
import { ValidateRouteParamsPartnerAndAID } from '../enrollment/validate-route-param-aid';
import {
  Banner,
  BannerContainer,
  BannerImage,
  ProductPitchActionStart,
  SalesProductPageContainer,
  SingleActionContainer,
  Tab,
  TabGroup,
  TabImage,
  TabbedContentContainer,
  WelcomeMessage,
} from './product-styles';

export const SalesPageContentConfig: TypeProductContentConfigMap = {
  [Partner.ACRISURE]: {
    [AllProducts.AppliancePlus]: acrisureAppliancePlusProductContent,
    [AllProducts.HomePlus]: acrisureHomePlusProductContent,
  },
};

type TypeProductContentConfigMap = {
  [key: string]: {
    [key: string]: TypeProductContentConfig;
  };
};

const d2cBaseUrl = process.env['NX_D2C_BASE_URL'];

const supportedPartners = [] as Partner[];

interface GetTabLinkInterface {
  partner: Partner;
  slug: string;
  aID: string;
  qID?: string;
}

interface GetD2CUrlByPartnerInterface {
  partner: Partner;
  baseUrl: string;
  contentUrl: string;
  aID: string;
  qID?: string;
}

const getTabLinkByPartner = ({
  partner,
  slug,
  aID,
  qID,
}: GetTabLinkInterface) => {
  if (partner === Partner.ACRISURE && qID) {
    return `/agent-enrollment/${slug}/${partner}/${aID}?q_id=${qID}`;
  }

  return `/agent-enrollment/${slug}/${partner}/${aID}`;
};

const getD2CUrlByPartner = ({
  partner,
  baseUrl,
  contentUrl,
  aID,
  qID,
}: GetD2CUrlByPartnerInterface) => {
  if (partner === Partner.ACRISURE && qID) {
    return `${baseUrl}${contentUrl}?a_id=${aID}&q_id=${qID}`;
  }

  return `${baseUrl}${contentUrl}?a_id=${aID}`;
};

export function NoAuthSalesProductPage() {
  const params = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qID = queryParams.get('q_id') || '';

  const partner = params.partner;
  const aID = params.aID;
  const product = params.product;

  const orgConfig = SalesPageContentConfig[partner as AllPartners];

  const productDefaultId = Object.keys(orgConfig || {})[0];
  const productDefaultSlug = ProductSlugs?.[productDefaultId];

  const productSlug = product || productDefaultSlug;

  const productKey = ProductSlugsInvertedKeys?.[productSlug];
  const content = orgConfig?.[productKey];
  const productName = content?.name;

  // Destructure Org Products into UI data
  type TabBanner = { isActive: boolean; image: { url: string; alt: string } };
  type Tab = { link: string } & TabBanner;
  const tabs: Tab[] = [];
  const banners: TabBanner[] = [];

  Object.keys(orgConfig || {}).forEach((productKey) => {
    const product = orgConfig[productKey];
    const slug = ProductSlugs[productKey];
    const isActive = slug === productSlug;

    const link = getTabLinkByPartner({
      partner: partner as Partner,
      slug: slug as string,
      aID: aID as string,
      qID: qID as string,
    });

    tabs.push({ isActive, image: product.tabImage, link });
    banners.push({ isActive, image: product.bannerImage });
  });

  // Event Handlers
  async function handleStartSale(e: { preventDefault: () => void }) {
    e.preventDefault();

    const url = getD2CUrlByPartner({
      partner: partner as Partner,
      baseUrl: d2cBaseUrl as string,
      contentUrl: content.d2cUri as string,
      aID: aID as string,
      qID: qID as string,
    });

    window.open(url, '_blank');
  }

  const NotFoundPageName = `${PageNames.HomePage} | Partner: ${partner} | Product: ${product} | Agent ID: ${aID}`;

  if (!partner || !aID || !isSupportedPartner(partner) || !content)
    return <NotFound pageName={NotFoundPageName} />;

  return (
    <ValidateRouteParamsPartnerAndAID pageName={NotFoundPageName}>
      <SalesProductPageContainer>
        <PageContainerWithViewAnalyticsHeaderFooter
          pageName={PageNames.HomePage}
          showUserAvatar={false}
        >
          <BannerContainer>
            {banners.map((banner) => (
              <Banner
                key={banner.image.url}
                className={banner.isActive ? 'active' : 'inactive'}
              >
                <BannerImage src={banner.image.url} alt={banner.image.alt} />
              </Banner>
            ))}
          </BannerContainer>

          <WelcomeMessage forwardedAs={'h1'} size={6} weight={'feather'}>
            Welcome!
          </WelcomeMessage>

          <TabbedContentContainer>
            {tabs.length > 1 && (
              <TabGroup>
                {tabs.map((tab) => (
                  <Tab
                    key={tab.image.url}
                    to={tab.link}
                    className={tab.isActive ? 'active' : 'inactive'}
                  >
                    <TabImage src={tab.image.url} alt={tab.image.alt} />
                  </Tab>
                ))}
              </TabGroup>
            )}
            <SingleActionContainer>
              <ProductPitchActionStart onClick={handleStartSale}>
                <Text size={4}>Start a {productName} sale</Text>
              </ProductPitchActionStart>
            </SingleActionContainer>
          </TabbedContentContainer>
        </PageContainerWithViewAnalyticsHeaderFooter>
      </SalesProductPageContainer>
    </ValidateRouteParamsPartnerAndAID>
  );
}

function isSupportedPartner(partner: string): boolean {
  return supportedPartners.includes(partner as Partner);
}
