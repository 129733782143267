import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import { useGetAffiliate } from '@3as-affiliates/shared/web/data-access/api-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { LoadingSpinnerPage } from '@3as-affiliates/shared/web/features';
import {
  AuthenticationState,
  getActiveAuthPersona,
  useAuth,
} from '@web/agent-auth';
import { useCallback, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const RouteWithUserInfo: React.FC = () => {
  const { authState, getAuthUserInfo, logOut } = useAuth();
  const { getAffiliate } = useGetAffiliate();
  const { updateAppInfo } = useAppData();

  const [userInfoFetch, setUserInfoFetch] = useState<
    'IN_PROGRESS' | 'SUCCESS' | 'ERROR' | 'UNAUTHORIZED'
  >('IN_PROGRESS');

  const loadAuthUserInfo = useCallback(async () => {
    try {
      const authUserInfo = await getAuthUserInfo();
      const activeAuthUserPersona = await getActiveAuthPersona(authUserInfo);

      if (!activeAuthUserPersona || !activeAuthUserPersona?.partner) {
        throw new Error('UNAUTHORIZED');
      }

      updateAppInfo({ partner: activeAuthUserPersona.partner });

      const affiliate = await getAffiliate();
      if (affiliate && affiliate.error) {
        throw new Error('UNAUTHORIZED');
      }
      setUserInfoFetch('SUCCESS');
    } catch (error) {
      if (error instanceof Error && error.message === 'UNAUTHORIZED') {
        setUserInfoFetch('UNAUTHORIZED');
      } else {
        setUserInfoFetch('ERROR');
        logOut();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAuthUserInfo, logOut]);

  useEffect(() => {
    loadAuthUserInfo();
  }, [loadAuthUserInfo]);

  if (authState === AuthenticationState.LoggedOut) {
    return <Navigate to={Routes.LoggedOutLanding} replace={true} />;
  }

  if (
    authState !== AuthenticationState.Uninitialized &&
    userInfoFetch === 'UNAUTHORIZED'
  ) {
    return <Navigate to={Routes.Unauthorized} replace={true} />;
  }

  if (userInfoFetch === 'SUCCESS') {
    return <Outlet />;
  }

  return <LoadingSpinnerPage pageName={PageNames.LoadingPage} />;
};
