import {
  Intro,
  TypeIntroComponentPassthroughFields,
} from '@3as-affiliates/partners/web/components';
import { PageNames, Routes } from '@3as-affiliates/partners/web/types-configs';
import {
  Footer,
  Header,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { breakpoints } from '@soluto-private/mx-asurion-ui-react';
import { Button } from '@soluto-private/mx-asurion-ui-react-v3';
import { useAuth } from '@web/agent-auth';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Error from '../partner-data/assets/error.png';

const Layout = styled.div`
  min-height: 100dvh;
  text-align: center;
  align-content: center;
  padding: 2.5rem;
`;

const ErrorImage = styled.img`
  margin-bottom: 16px;
  width: 100%;
  max-width: 1065px;

  ${breakpoints.md} {
    width: auto;
  }
`;
const StyledButton = styled(Button)`
  max-width: 1065px;
  margin: auto;
`;

const passthroughFields: TypeIntroComponentPassthroughFields = {
  content: [
    {
      fields: {
        cmsDescription: '',
        headingLevel: 'h1',
        headline: 'Sorry, you do not have access',
      },
    },
  ],
};

export const Unauthorized = () => {
  const { logOut } = useAuth();

  const navigate = useNavigate();
  const onBackToLoginClick = () => {
    logOut();
    navigate(Routes.LoggedOutLanding);
  };

  return (
    <PageContainerWithViewAnalytics pageName={PageNames.Unauthorized}>
      <Header pageName={PageNames.Unauthorized} />
      <Layout>
        <Intro {...passthroughFields} />
        <p>
          If you think there's been a mistake, <br /> please get in touch with
          your Asurion contact
        </p>
        <StyledButton onClick={onBackToLoginClick}>
          {'Back to login page'}
        </StyledButton>
        <ErrorImage src={Error} alt="Error Backdrop" />
      </Layout>
      <Footer />
    </PageContainerWithViewAnalytics>
  );
};
