import { HttpStatusCodes } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@web/agent-auth';
import { useAffiliateApi } from '../api-gateway';
import {
  genericServerError,
  genericSuccessResponse,
  ResType,
  somethingWentWrongError,
  userNotFoundError,
} from '../types-configs/standardizeDataResponse';
import { parseAffiliateInfo } from '../utils/parse-affiliate-info';

export const useGetAffiliate = () => {
  const { updateAffiliateInfo } = useAppData();
  const { getAffiliateInfo } = useAffiliateApi();
  const { dispatch } = useAnalytics();
  const { getAuthUserInfo } = useAuth();

  const getAffiliate = async (): Promise<ResType> => {
    const { asurionId } = await getAuthUserInfo();

    try {
      if (!asurionId) {
        return somethingWentWrongError;
      }
      const getAffRes = await getAffiliateInfo(asurionId);
      dispatch('API Call Success', {});

      if (getAffRes.status === HttpStatusCodes.OK) {
        const affiliateInfo = parseAffiliateInfo(getAffRes);
        updateAffiliateInfo(affiliateInfo);
        return { statusCode: getAffRes.status, ...genericSuccessResponse };
      } else if (getAffRes.status === HttpStatusCodes.NotFound) {
        return { statusCode: getAffRes.status, ...userNotFoundError };
      } else {
        return { statusCode: getAffRes.status, ...genericServerError };
      }
    } catch (error) {
      dispatch('API Call Failed', {});
      return genericServerError;
    }
  };
  return { getAffiliate };
};
