import { ContentfulComponentRenderer } from '@3as-affiliates/partners/web/components';
import { PageNames } from '@3as-affiliates/partners/web/types-configs';
import {
  ContentfulMarketingPage,
  Partner,
  Products,
} from '@3as-affiliates/shared/types-configs';
import {
  Footer,
  Header,
  LoadingSpinner,
  PageContainerWithViewAnalytics,
} from '@3as-affiliates/shared/web/components';
import { ContentfulContext } from '@3as-affiliates/shared/web/utils/contentful';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FlattenSimpleInterpolation } from 'styled-components';
import { environment } from '../../environments/environment';
import { comparionHomePlusStaticEntry } from '../../partner-data/content/home-plus-remarketing-page';
import { NotFound } from '../NotFound';
import { getTermsAndConditionsParams } from '../TermsAndConditionsPartners';
import { EnrollmentPageStyles } from './enrollment-page-style';
import { acrisureAppliancePlusCSS } from './styles/acrisure/appliance-plus';
import { acrisureHomePlusCSS } from './styles/acrisure/home-plus';
import { asurionAppliancePlusCSS } from './styles/asurion/appliance-plus';
import { comparionApplianceProtectCSS } from './styles/comparion/appliance-protect';
import { comparionHomePlusCSS } from './styles/comparion/home-plus';
import { viuApplianceProtectCSS } from './styles/viu/appliance-protect';
import { viuHomePlusCSS } from './styles/viu/home-plus';
import { transformPageData } from './transform-page-data';
import {
  ValidateRouteParamsAID,
  ValidateRouteParamsPartnerAndAID,
} from './validate-route-param-aid';

// --- Configuration --- //
const termsOfUseUrl = `/terms-conditions`;
const privacyPolicyUrl = `/privacy-notice`;

// Allowed Products/Partners URL validation
// is driven by existing configurations
export const pageConfig: TypePageConfigMap = {
  [Products.APPLIANCE_PROTECT]: {
    [Partner.COMPARION]: {
      contentfulEntryId: '3RhD7IuKxZ5V0FAYnw1V3s',
      d2cStep: 'affiliate/personal-info',
      pageName: PageNames.EnrollmentApplianceProtectComparion,
      css: comparionApplianceProtectCSS,
      termsOfUseUrl,
      privacyPolicyUrl,
    },
    [Partner.VIU]: {
      contentfulEntryId: '5xubJ014pnNDNNXkWPxGpT',
      d2cStep: 'app/personal-info',
      pageName: PageNames.EnrollmentApplianceProtectVIU,
      css: viuApplianceProtectCSS,
      termsOfUseUrl: 'https://www.asurion.com/legal/viu',
      privacyPolicyUrl,
    },
  },
  [Products.APPLIANCE_PLUS]: {
    [Partner.ACRISURE]: {
      contentfulEntryId: '7oUitQ71ZC6c626zHi8Wrs',
      d2cStep: 'app/personal-info',
      pageName: PageNames.EnrollmentAppliancePlusAcrisure,
      css: acrisureAppliancePlusCSS,
      termsOfUseUrl,
      privacyPolicyUrl,
    },
    [Partner.ASURION]: {
      contentfulEntryId: '6FRuCfILGHrjRccOAlKwZH',
      d2cStep: 'affiliate/personal-info',
      pageName: PageNames.EnrollmentAppliancePlusAsurion,
      css: asurionAppliancePlusCSS,
      termsOfUseUrl,
      privacyPolicyUrl,
    },
  },
  [Products.HOME_PLUS]: {
    [Partner.COMPARION]: {
      contentfulEntryId: '20Z2YmRdd4NupCPuSWFnf5',
      d2cStep: 'app/personal-info',
      pageName: PageNames.EnrollmentHomePlusComparion,
      css: comparionHomePlusCSS,
      termsOfUseUrl,
      privacyPolicyUrl,
    },
    [Partner.ACRISURE]: {
      contentfulEntryId: '7n5zepoLWbVgFtX6aXZN7d',
      d2cStep: 'app/personal-info',
      pageName: PageNames.EnrollmentHomePlusAcrisure,
      css: acrisureHomePlusCSS,
      termsOfUseUrl,
      privacyPolicyUrl,
    },
    [Partner.VIU]: {
      contentfulEntryId: '4kY1ZJVxU0XtKrVP27vTSC',
      d2cStep: 'app/personal-info',
      pageName: PageNames.EnrollmentHomePlusVIU,
      css: viuHomePlusCSS,
      termsOfUseUrl: 'https://www.asurion.com/legal/viu',
      privacyPolicyUrl,
    },
  },
};

type TypePageConfig = {
  contentfulEntryId: string;
  d2cStep: string;
  pageName: PageNames;
  css: FlattenSimpleInterpolation;
  termsOfUseUrl: string;
  privacyPolicyUrl: string;
};

type TypePageConfigMap = {
  [k in Products]?: {
    [k in Partner]?: TypePageConfig;
  };
};

// --- Environment Variables --- //
const D2C_BASE_URL = process.env['NX_D2C_BASE_URL'];

// --- Utility Function --- //

interface GetEnrollmentUrlInterface {
  product: Products;
  partner: Partner;
  step: string;
  aID: string;
  qID?: string;
}

function getEnrollmentUrl({
  product,
  partner,
  step,
  aID,
  qID,
}: GetEnrollmentUrlInterface) {
  if (partner === Partner.ACRISURE && qID) {
    return `${D2C_BASE_URL}/${product}/${partner}/${step}?a_id=${aID}&q_id=${qID}`;
  }

  return `${D2C_BASE_URL}/${product}/${partner}/${step}?a_id=${aID}`;
}

export function EnrollmentRoute() {
  const { product, partner, aID } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qID = queryParams.get('q_id') || '';

  const config = pageConfig[product as Products]?.[partner as Partner];

  const { useMarketingPage } = useContext(ContentfulContext);

  const { entry, CFQueryContainer } = useMarketingPage({
    entryId: config?.contentfulEntryId,
  });

  const hasProduct = product !== undefined;
  const hasPartner = partner !== undefined;
  const hasAID = aID !== undefined;
  const hasConfig = config !== undefined;

  const NotFoundPageName = `${PageNames.Enrollment} | Partner: ${partner} | Product: ${product} | Agent ID: ${aID}`;
  if (!hasAID || !hasConfig || !hasProduct || !hasPartner)
    return <NotFound pageName={NotFoundPageName} />;

  return (
    <ValidateRouteParamsPartnerAndAID
      pageName={NotFoundPageName}
      asurionRerouteEnabled={true}
    >
      <CFQueryContainer
        error={<NotFound pageName={NotFoundPageName} />}
        loading={<LoadingSpinner />}
      >
        <EnrollmentPage
          product={product}
          partner={partner}
          aID={aID}
          qID={qID}
          config={config}
          entry={entry}
        />
      </CFQueryContainer>
    </ValidateRouteParamsPartnerAndAID>
  );
}

export function DeprecatedEnrollmentRoute() {
  const { aID } = useParams();
  const config = pageConfig[Products.HOME_PLUS]?.[Partner.COMPARION];

  const hasAID = aID !== undefined;
  const hasConfig = config !== undefined;
  const NotFoundPageName = `${PageNames.Enrollment} | Deprecated Route | Partner: ${Partner.COMPARION} | Product: ${Products.HOME_PLUS} | Agent ID: ${aID}`;

  if (!hasAID || !hasConfig) return <NotFound pageName={NotFoundPageName} />;

  return (
    <ValidateRouteParamsAID pageName={NotFoundPageName}>
      <EnrollmentPage
        product={Products.HOME_PLUS}
        partner={Partner.COMPARION}
        aID={aID}
        config={config}
        entry={comparionHomePlusStaticEntry as ContentfulMarketingPage}
      />
    </ValidateRouteParamsAID>
  );
}

type EnrollmentPageProps = {
  product: string;
  partner: string;
  aID: string;
  qID?: string;
  config: TypePageConfig;
  entry: ContentfulMarketingPage;
};

export function EnrollmentPage({
  product,
  partner,
  aID,
  qID,
  config,
  entry,
}: EnrollmentPageProps) {
  const enrollmentUrl = getEnrollmentUrl({
    product: product as Products,
    partner: partner as Partner,
    step: config?.d2cStep,
    aID,
    qID,
  });

  const data = transformPageData(entry, enrollmentUrl, aID, qID);
  const productTermsParams = getTermsAndConditionsParams(
    product as Products,
    partner as Partner
  );
  const productTermsUrl = productTermsParams
    ? `${environment.host}/terms-conditions/${product}/${partner}`
    : undefined;
  return (
    <EnrollmentPageStyles $css={config.css}>
      <PageContainerWithViewAnalytics pageName={config.pageName}>
        <Header
          partner={partner as Partner}
          coBrand={true}
          logoHeight={'40px'}
          pageName={config.pageName}
        />
        {data && <ContentfulComponentRenderer {...data} />}
        <Footer
          productTermsUrl={productTermsUrl}
          termsOfUseUrl={termsOfUseUrl}
          privacyPolicyUrl={privacyPolicyUrl}
        />
      </PageContainerWithViewAnalytics>
    </EnrollmentPageStyles>
  );
}
