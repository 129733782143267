import { createContext, useContext } from 'react';
import { AuthContextProps } from '../types';

export const AuthContext = createContext<AuthContextProps | undefined>(
  undefined
);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth context not initialized');
  return context;
};
