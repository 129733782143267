import {
  AllPartners,
  CampaignIds,
  OrganizationIds,
} from '@3as-affiliates/shared/types-configs';
import { PartnerPersona } from '../types';

export const personaSourcePlatform = 'AFFILIATE_PLATFORM';

export enum personaEntityTypes {
  AFFILIATION = 'AFFILIATION',
}

export const personaConfig: Array<PartnerPersona> = [
  {
    partner: AllPartners.Comparion,
    sourcePlatform: personaSourcePlatform,
    entityType: personaEntityTypes.AFFILIATION,
    externalId: `${OrganizationIds[AllPartners.Comparion]}|${
      CampaignIds.ComparionDefault
    }`,
  },
  {
    partner: AllPartners.VIU,
    sourcePlatform: personaSourcePlatform,
    entityType: personaEntityTypes.AFFILIATION,
    externalId: `${OrganizationIds[AllPartners.VIU]}|${CampaignIds.VIUDefault}`,
  },
  {
    partner: AllPartners.Asurion,
    sourcePlatform: personaSourcePlatform,
    entityType: personaEntityTypes.AFFILIATION,
    externalId: `${OrganizationIds[AllPartners.Asurion]}|${
      CampaignIds.AsurionFieldExpertOps
    }`,
  },
];
