import { HttpStatusCodes } from '@3as-affiliates/shared/utils/fetch-http-client';
import { useAppData } from '@3as-affiliates/shared/web/data-access/data-store';
import { useAnalytics } from '@3as-affiliates/shared/web/utils/analytics';
import { useAuth } from '@web/agent-auth';
import { useAffiliateApi } from '../api-gateway';
import {
  genericServerError,
  genericSuccessResponse,
  ResType,
  somethingWentWrongError,
  userAlreadyExistsError,
} from '../types-configs/standardizeDataResponse';
import { parseAffiliateInfo } from '../utils/parse-affiliate-info';

interface SignupAffiliateType {
  firstName: string;
  lastName: string;
  companyName: string;
  role: string;
}

export const useCreateAffiliate = () => {
  const { createAffiliate } = useAffiliateApi();
  const { dispatch } = useAnalytics();
  const { updateAffiliateInfo } = useAppData();
  const { getAuthUserInfo } = useAuth();

  const signupAffiliate = async (
    affiliate: SignupAffiliateType
  ): Promise<ResType> => {
    const { asurionId, email } = await getAuthUserInfo();

    if (!asurionId) {
      return somethingWentWrongError;
    }
    const affiliateObj = { ...affiliate, asurionId, email };
    try {
      const createAffRes = await createAffiliate(affiliateObj);

      dispatch('API Call Success', {});

      if (createAffRes.status !== HttpStatusCodes.Created) {
        if (createAffRes.status === HttpStatusCodes.Conflict) {
          return userAlreadyExistsError;
        } else {
          return { statusCode: createAffRes.status, ...genericServerError };
        }
      }

      const affiliateInfo = parseAffiliateInfo(createAffRes);
      updateAffiliateInfo(affiliateInfo);

      return { statusCode: createAffRes.status, ...genericSuccessResponse };
    } catch (error) {
      dispatch('API Call Failed', {});
      return genericServerError;
    }
  };

  return { signupAffiliate };
};
