import { Log, UserManager } from 'oidc-client-ts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../context';
import { AuthProviderProps, AuthenticationState } from '../types';

export const AuthProvider: React.FC<AuthProviderProps> = ({
  authOpenIdConfig,
  isProd,
  children,
}) => {
  const [authState, setAuthState] = useState<AuthenticationState>(
    AuthenticationState.Uninitialized
  );

  if (!isProd) {
    Log.setLogger(console);
    Log.setLevel(4);
  }

  const openIdUserManager = useMemo(
    () => new UserManager(authOpenIdConfig),
    [authOpenIdConfig]
  );

  openIdUserManager.events.addUserLoaded(() => {
    if (authState !== AuthenticationState.LoggedIn) {
      setAuthState(AuthenticationState.LoggedIn);
    }
  });

  openIdUserManager.events.addUserUnloaded(() => {
    setAuthState(AuthenticationState.LoggedOut);
  });

  const getAuthUserInfo = useCallback(async () => {
    const user = await openIdUserManager.getUser();
    return {
      asurionId: (user?.profile?.asurion_id || '') as string,
      email: (user?.profile?.principal_identifier || '') as string,
      accessToken: (user?.access_token || '') as string,
      personas: (user?.profile?.personas || []) as [],
    };
  }, [openIdUserManager]);

  const logIn = useCallback(() => {
    setAuthState(AuthenticationState.InProgress);
    openIdUserManager.signinRedirect();
  }, [openIdUserManager]);

  const loginCallback = useCallback(() => {
    openIdUserManager
      .signinRedirectCallback()
      .then((user) => {
        if (user) {
          setAuthState(AuthenticationState.LoggedIn);
        }
      })
      .catch((e) => {
        setAuthState(AuthenticationState.LoggedOut);
      });
  }, [openIdUserManager]);

  const logOut = useCallback(() => {
    openIdUserManager.signoutRedirect();
  }, [openIdUserManager]);

  useEffect(() => {
    openIdUserManager.getUser().then((user) => {
      if (user) {
        if (user.refresh_token) {
          openIdUserManager
            .signinSilent()
            .then(() => {
              setAuthState(AuthenticationState.LoggedIn);
            })
            .catch((e) => {
              setAuthState(AuthenticationState.LoggedOut);
              logOut();
            });
        } else if (!user.expired) {
          setAuthState(AuthenticationState.LoggedIn);
        }
      } else {
        setAuthState(AuthenticationState.LoggedOut);
      }
    });
  }, [logOut, openIdUserManager]);

  const value = {
    authState,
    getAuthUserInfo,
    logIn,
    loginCallback,
    logOut,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
