import { AllPartners } from '@3as-affiliates/shared/types-configs';
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { ReactElement } from 'react';

export enum AuthenticationState {
  Uninitialized = 'UNINITIALIZED',
  LoggedOut = 'LOGGED_OUT',
  InProgress = 'IN_PROGRESS',
  LoggedIn = 'LOGGED_IN',
}

export enum PersonaStatus {
  Verified = 'VERIFIED',
}

export type PartnerPersona = {
  partner: AllPartners;
  sourcePlatform?: string;
  entityType?: string;
  externalId?: string;
  status?: PersonaStatus;
  verifiedAt?: string;
};

export interface AuthOpenIdConfig extends UserManagerSettings {
  authority: string;
  client_id: string;
  redirect_uri: string;
  silent_redirect_uri: string;
  post_logout_redirect_uri: string;
  response_type: string;
  scope: string;
  redirectMethod?: 'replace' | 'assign';
  automaticSilentRenew?: boolean;
  userStore?: WebStorageStateStore;
}

export interface Persona {
  id: string;
  sourcePlatform: string;
  entityType: string;
  externalId: string;
  status: PersonaStatus;
  verifiedAt: Date;
}

export interface AuthUserProfile {
  asurionId: string;
  email: string;
  accessToken: string;
  personas: Persona[];
}

export type AuthContextProps = {
  authState: AuthenticationState;
  getAuthUserInfo: () => Promise<AuthUserProfile>;
  logIn: () => void;
  loginCallback: () => void;
  logOut: () => void;
};

export interface AuthProviderProps {
  isProd: boolean;
  authOpenIdConfig: AuthOpenIdConfig;
  children: ReactElement | ReactElement[];
}
