// ToDo: move this check to getAffiliate API

import { DecommissionedOrganizations } from '@3as-affiliates/shared/types-configs';
import { personaConfig, personaSourcePlatform } from '../configs';
import { AuthUserProfile, PartnerPersona } from '../types';

export const getActiveAuthPersona = async (
  authUserInfo: AuthUserProfile
): Promise<PartnerPersona | null> => {
  const authUserPersonas = authUserInfo?.personas;

  if (!authUserPersonas || authUserPersonas.length < 1) return null;

  const activePersonas = authUserPersonas.filter(
    ({ sourcePlatform, externalId }) => {
      if (sourcePlatform === personaSourcePlatform) {
        const organizationId = externalId.split('|')[0];
        return !DecommissionedOrganizations.includes(organizationId);
      } else {
        return false;
      }
    }
  );

  if (activePersonas.length < 1) {
    return null;
  }

  const matchingAuthUserPersona = personaConfig.find((platformPersonaItem) =>
    activePersonas.some(
      ({ sourcePlatform, entityType, externalId }) =>
        sourcePlatform === platformPersonaItem.sourcePlatform &&
        entityType === platformPersonaItem.entityType &&
        externalId === platformPersonaItem.externalId
    )
  );

  return matchingAuthUserPersona ? matchingAuthUserPersona : null;
};
